import React from "react";
import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { REPORT_RANGES, REPORT_TYPE_IDS } from "../../store/constants";

import ServiceTimePerService from "../../components/organization/reports/ServiceTimePerService";
import { getReportDescription } from "../../store/helpers";

const ServiceTimePerServicePage = ({
  showFilterForm = true,
  reportIndex = "**REPORT_INDEX**",
}) => {
  const { reportRange, reportsData, branchId } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId =
    reportRange === REPORT_RANGES.WEEK
      ? REPORT_TYPE_IDS.weekly_branch_service_time_per_service
      : REPORT_TYPE_IDS.monthly_branch_service_time_per_service;

  const reportTitle = `${reportIndex}. Service Time Per Service`;
  console.log(reportTitle);

  let reports = reportsData.filter((a) => a.reportTypeId === reportTypeId);
  if (branchId) {
    reports = reports.filter((a) => a.branchId === branchId);
  }
  const data = reports.map((a) => a.data);
  console.log(data, "data");

  return (
    <Box>
      <Box mb="20px">
        <h1>{reportTitle}</h1>
        <p>{getReportDescription(reportRange).ServiceTimePerServiceBranches}</p>
      </Box>
      {!_.isEmpty(data)
        ? data.map((a, index) => {
            return (
              <ServiceTimePerService
                key={index}
                branchId={a.branchId}
                reportIndex={reportIndex}
                branchIndex={index + 1}
                data={a.servicesData}
                branchName={a.branchName}
                numServices={a.numServices}
              />
            );
          })
        : null}
    </Box>
  );
};

export default ServiceTimePerServicePage;
