import React from "react";
import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import _ from "lodash";

import OrganizationTickets from "../../../components/organization/reports/daily/OrganizationTickets";
import { getReportDescription } from "../../../store/helpers";
import { REPORT_TYPE_IDS } from "../../../store/constants";

const DailyOrganizationTickets = ({
  showFilterForm = true,
  reportIndex = "**REPORT_INDEX**",
}) => {
  const { reportRange, reportsData } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId = REPORT_TYPE_IDS.daily_organization_tickets
  const report = reportsData.find((a) => a.reportTypeId === reportTypeId);
  const { data } = report;

  const reportTitle = `${reportIndex}. Organization Tickets`;
  console.log(reportTitle);
  return (
    <Box>
      <Box mb="20px">
        <h1>{reportTitle}</h1>
        <p>{getReportDescription(reportRange).Tickets}</p>
      </Box>
      {!_.isEmpty(data) ? (
        <OrganizationTickets reportIndex={reportIndex} data={data[0]} />
      ) : null}
    </Box>
  );
};

export default DailyOrganizationTickets;
