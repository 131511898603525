import LineGraph from "../../LineGraph";
import ArrivalRatesPerDayTable from "../tables/ArrivalRatesPerDayTable";


import { Box } from "@chakra-ui/react";

const ArrivalRatesPerDay = ({ reportIndex = 1, data }) => {

  const title = `${reportIndex}.1. Entire Organization`;
  console.log(title);
  let {min, max, chart, average, total} = data;

  return (
    <>
      <Box className="reportContainer" mt="20px">
        <ArrivalRatesPerDayTable
          average={(average)}
          min={(min)}
          max={(max)}
          total={(total)}
          title={title}
        />

        <Box mt="20px" mb="20px">
          <LineGraph
            chart={chart}
            height={"300px"}
            title={"Entire Organization Arrival Rates, Day by Day Comparison"}
          />
        </Box>
      </Box>
    </>
  );
};

export default ArrivalRatesPerDay;
