import {
  Box,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import AgentServiceTimePerServiceLineGraph from "../../charts/daily/AgentServiceTimePerServiceLineGraph";

import { useEffect, useState } from "react";
import { formatTime } from "../../../../store/helpers";

const AgentServicesTableRows = ({ data }) => {
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    const calculateSecondColumnWidth = (table) => {
      const secondColumn = table.querySelector("tr > td:nth-child(2)");
      if (secondColumn) {
        setMaxWidth((prevMaxWidth) =>
          Math.max(prevMaxWidth, secondColumn.offsetWidth)
        );
      }
    };
    document.querySelectorAll("table").forEach((table) => {
      calculateSecondColumnWidth(table);
    });
  }, []);

  useEffect(() => {
    document.querySelectorAll("table").forEach((table) => {
      table.querySelectorAll("tr > td:nth-child(2)").forEach((secondColumn) => {
        secondColumn.style.width = `${maxWidth}px`;
      });
    });
  }, [maxWidth]);
  return (
    <>
      {data.map((agentData, index) => {
        const { agentName, serviceName } = agentData;
        return agentData.sum ? (
          <>
            <Box mt="20px" mb="20px">
              <Table className="stripedTable" size="sm">
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>Agent Name</Th>
                    <Th borderRight="1px solid #dadce0">Total Service Time</Th>
                    <Th borderRight="1px solid #dadce0">Average</Th>
                    <Th>Min</Th>
                    <Th>Max</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>{index + 1}</Td>
                    <Td>{agentData.agentName}</Td>
                    <Td borderRight="1px solid #dadce0">
                      {" "}
                      <Text color={"var(--green)"}>
                        {formatTime(agentData.sum)}
                        <Box
                          color="gray.500"
                          fontWeight="semibold"
                          fontSize="xs"
                          textTransform="uppercase"
                        >
                          ({agentData.totalPct.toFixed(2)}%)
                        </Box>
                      </Text>
                    </Td>
                    <Td borderRight="1px solid #dadce0">
                      {formatTime(agentData.average)}
                    </Td>
                    <Td>{formatTime(agentData.min)}</Td>
                    <Td>{formatTime(agentData.max)}</Td>
                  </Tr>
                  <Tr>
                    <Td colSpan={6}>
                      <Box mt="20px" mb="20px">
                        <AgentServiceTimePerServiceLineGraph
                          title={`${agentName}: ${serviceName}, Hour by Hour Comparison`}
                          chart={agentData.chart}
                          height={"200px"}
                        />
                      </Box>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </>
        ) : (
          <>
            <Text fontWeight={500}>
              No data for agent: {agentData.agentName}
            </Text>
          </>
        );
      })}
    </>
  );
};

const AgentServiceTimePerServiceTable = ({
  serviceData,
  branchId,
  branchName,
  reportIndex,
  branchIndex,
  index,
}) => {
  const { totalServiceTime, agentsData, serviceName } = serviceData;

  const title = `${reportIndex || ""}.${branchIndex + 1}.${
    index + 1
  }. ${serviceName}`;
  console.log(title);

  return (
    <Box>
      <h3>{title}</h3>
      {!totalServiceTime && (
        <Text fontSize="sm">No data available for this report</Text>
      )}
      <Tag>
        <p className="branchTag">{branchName} Branch</p>
      </Tag>
      {totalServiceTime ? (
        <>
          <AgentServicesTableRows data={agentsData} />
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default AgentServiceTimePerServiceTable;
