import { ACTION_TYPES } from "../actions/types";
import { APP_STATUSES } from "../constants";

const initialState = {
  appStatus: APP_STATUSES.LOGGED_OUT, //logged in or not
  authLoading: false,
  userId: "",
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_APP_STATUS:
      return {
        ...state,
        appStatus: action.payload,
      };

    case ACTION_TYPES.SET_AUTH_LOADING:
      return {
        ...state,
        authLoading: action.payload,
      };

    case ACTION_TYPES.SET_USER_ID:
      return {
        ...state,
        userId: action.payload,
      };
    default:
      return state;
  }
}
