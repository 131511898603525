import { Box, Heading } from "@chakra-ui/react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const TicketsPerServiceLineGraph = ({ title, chart, chartHeight }) => {
  return (
    <Box width="100%" h={chartHeight}>
      <ResponsiveContainer>
        <LineChart
          data={chart}
          margin={{
            top: 0,
            right: 10,
            left: -10,
            bottom: 15,
          }}
        >
          <CartesianGrid stroke="#ebebeb" vertical={false} />
          <XAxis
            dataKey="hour"
            name="Hour"
            axisLine={{ stroke: "var(--darkGrey)", strokeWidth: 1 }}
          />
          <YAxis axisLine={{ stroke: "var(--darkGrey)", strokeWidth: 1 }} />
          <Tooltip />
          <Legend width="100%" />
          <Line
            type="monotone"
            strokeWidth={3}
            dataKey="total"
            name="Total Tickets"
            stroke={"var(--blue)"}
            dot={{ fill: "var(--blue)", r: 2 }}
            activeDot={{ r: 4 }}
            animationDuration={0}
          />
          <Line
            type="monotone"
            strokeWidth={3}
            dataKey="completed"
            name="Completed"
            stroke={"var(--green)"}
            dot={{ fill: "var(--green)", r: 2 }}
            activeDot={{ r: 4 }}
            animationDuration={0}
          />
          <Line
            type="monotone"
            strokeWidth={3}
            dataKey="noShow"
            name="No Show"
            stroke={"var(--red)"}
            dot={{ fill: "var(--red)", r: 2 }}
            activeDot={{ r: 4 }}
            animationDuration={0}
          />
          <Line
            type="monotone"
            strokeWidth={3}
            dataKey="unattended"
            name="Unattended"
            stroke={"var(--orange)"}
            dot={{ fill: "var(--orange)", r: 2 }}
            activeDot={{ r: 4 }}
            animationDuration={0}
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default TicketsPerServiceLineGraph;
