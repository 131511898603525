import React from "react";
import _ from "lodash";

import { Box } from "@chakra-ui/react";

import OrganizationTicketsTable from "../../tables/daily/OrganizationTicketsTable";

const OrganizationTickets = ({ reportIndex, data }) => {
  return (
    <Box>
      <OrganizationTicketsTable
        table={[data]}
        chart={data.chart}
        reportIndex={reportIndex}
      />
    </Box>
  );
};

export default OrganizationTickets;
