import { Box } from "@chakra-ui/react";
import BranchArrivalRatesPerService from "../../../components/branches/reports/daily/BranchArrivalRatesPerService";
import {
  REPORT_TYPE_IDS,
} from "../../../store/constants";
import { useSelector } from "react-redux";
import Loader from "../../../components/loader";
import { getReportDescription } from "../../../store/helpers";
import _ from "lodash";

const DailyBranchArrivalRatesPerService = ({
  showFilterForm = true,
  reportIndex = "**REPORT_INDEX**",
}) => {
  const { loading, reportRange, reportsData, branchId } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId = REPORT_TYPE_IDS.daily_branch_arrival_rates_per_service

  const title = `${reportIndex || ""}. Arrival Rates Per Service`;
  console.log(title);

  let reports = reportsData.filter((a) => a.reportTypeId === reportTypeId);
  if (branchId) {
    reports = reports.filter((a) => a.branchId === branchId);
  }
  const data = reports.map((a) => a.data);

  return (
    <Box>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <h1>{title}</h1>
          <p>
            {getReportDescription(reportRange).BranchesArrivalRatesPerService}
          </p>
          {!_.isEmpty(data) ? (
            <>
              <BranchArrivalRatesPerService
                reportIndex={reportIndex}
                data={data}
              />
            </>
          ) : (
            <Box mt={10}>
              <p>No data available in this search</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default DailyBranchArrivalRatesPerService;
