import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import BranchAgentServiceTimePerService from "../../../components/branches/reports/daily/BranchAgentServiceTimePerService";
import { REPORT_TYPE_IDS } from "../../../store/constants";
import Loader from "../../../components/loader";
import { getReportDescription } from "../../../store/helpers";
import _ from "lodash";

const DailyBranchAgentServiceTimePerService = ({
  showFilterForm = true,
  reportIndex = "**REPORT_INDEX**",
}) => {
  const { loading, reportRange, reportsData, branchId } = useSelector(
    (state) => state.defaultReducer
  );

  const reportTypeId =
    REPORT_TYPE_IDS.daily_branch_agent_service_time_per_service;

  let reports = reportsData.filter((a) => a.reportTypeId === reportTypeId);
  if (branchId) {
    reports = reports.filter((a) => a.branchId === branchId);
  }
  const data = reports.map((a) => a.data);

  const title = `${reportIndex}. Agent Service Time Per Service`;
  console.log(title);

  return (
    <Box>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <h1>{title}</h1>
          <p>{getReportDescription(reportRange).AgentServiceSessionTime}</p>
          {!_.isEmpty(data) ? (
            <>
              <BranchAgentServiceTimePerService
                reportIndex={reportIndex}
                data={data}
              />
            </>
          ) : (
            <Box mt={10}>
              <p>No data available in this search</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default DailyBranchAgentServiceTimePerService;
