import { Box } from "@chakra-ui/react";
import ArrivalRatesPerServiceTable from "../../tables/daily/ArrivalRatesPerServiceTable";


const BranchArrivalRatesPerService = ({ reportIndex, data }) => {
  return (
    <Box>
      {data.map(({ branchName, servicesData }, branchIndex) => {
        const title = `${reportIndex || ""}.${branchIndex + 1}. ${branchName}`;
        console.log(title);
        return (
          <>
            <h2>{title}</h2>
            {servicesData.map((data, index) => {
              return (
                <ArrivalRatesPerServiceTable
                  index={index}
                  data={data}
                  branchIndex={branchIndex}
                  reportIndex={reportIndex}
                  branchName={branchName}
                />
              );
            })}
          </>
        );
      })}
    </Box>
  );
};

export default BranchArrivalRatesPerService;
