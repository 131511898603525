import { Box } from "@chakra-ui/react";

import { useSelector } from "react-redux";

import { getReportDescription } from "../../store/helpers";
import ArrivalRatesByServiceReport from "../../components/organization/reports/ArrivalRatesByService";
import Loader from "../../components/loader";
import { REPORT_RANGES, REPORT_TYPE_IDS } from "../../store/constants";
import _ from "lodash";

const ArrivalRatesPerService = ({
  showFilterForm = true,
  reportIndex = "**REPORT_INDEX**",
}) => {
  const { loading, reportRange, reportsData } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId =
    reportRange === REPORT_RANGES.WEEK
      ? REPORT_TYPE_IDS.weekly_organization_arrival_rates_per_service
      : REPORT_TYPE_IDS.monthly_organization_arrival_rates_per_service;

  const title = `${reportIndex}. Arrival Rates Per Service`;
  console.log(title);
  const report = reportsData.find((a) => a.reportTypeId === reportTypeId);
  const { data } = report;

  return (
    <Box>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <h1>{title}</h1>
          <p>{getReportDescription(reportRange).ArrivalRatesByService}</p>
          {!_.isEmpty(data) ? (
            <>
              <Box mt={10}>
                <ArrivalRatesByServiceReport
                  reportIndex={reportIndex}
                  data={data}
                />
              </Box>
            </>
          ) : (
            <Box mt={10}>
              <p>No data available in this search</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ArrivalRatesPerService;
