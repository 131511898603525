import TicketsPerAgentTable from "../tables/TicketsPerAgentTable";

import { Box } from "@chakra-ui/react";


const TicketsPerAgent = ({ reportIndex, data }) => {


  return (
    <Box>
      {data.map((data, index) => {
        const { branchName } = data;
        const title = `${reportIndex || ""}.${index + 1}. ${branchName}`;
        console.log(title);
        return (
          <Box key={index}>
            <h2>{title}</h2>
            <TicketsPerAgentTable reportIndex={reportIndex} data={data} branchIndex={index} />
          </Box>
        );
      })}
    </Box>
  );
};

export default TicketsPerAgent;
