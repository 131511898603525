import { Box, Table, Thead, Tr, Th, Td, Text, Tbody } from "@chakra-ui/react";
import { useEffect, useId, useState } from "react";
import { formatNumber } from "../../../../store/helpers";
import LineGraph from "../../../daily/LineGraph";

const ArrivalRatesPerServiceTable = ({ index, serviceData, reportIndex }) => {
  const [maxWidth, setMaxWidth] = useState(0);
  useEffect(() => {
    const calculateSecondColumnWidth = (table) => {
      const secondColumn = table.querySelector("tr > td:nth-child(2)");
      if (secondColumn) {
        setMaxWidth((prevMaxWidth) =>
          Math.max(prevMaxWidth, secondColumn.offsetWidth)
        );
      }
    };
    document.querySelectorAll("table").forEach((table) => {
      calculateSecondColumnWidth(table);
    });
  }, []);

  useEffect(() => {
    document.querySelectorAll("table").forEach((table) => {
      table.querySelectorAll("tr > td:nth-child(2)").forEach((secondColumn) => {
        secondColumn.style.width = `${maxWidth}px`;
      });
    });
  }, [maxWidth]);
  const id = useId();

  const { min, max, total, average, serviceName, totalPct, chart } =
    serviceData;
  const heading = `${serviceName}`;
  const title = `${reportIndex || ""}.${index + 1}. ${heading}`;
  console.log(title);
  return (
    <Box key={index} className="reportContainer" mt="20px">
      <h3>
        {title}
        {!total && <Text fontSize="sm">No data available for this report</Text>}
      </h3>

      <Box>
        {total ? (
          <Table className="stripedTable" size="sm">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th borderRight="1px solid #dadce0">Total</Th>
                <Th>Average</Th>
                <Th>Min</Th>
                <Th>Max</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr key={id}>
                <Td>{index + 1}</Td>
                <Td Td borderRight="1px solid #dadce0">
                  {" "}
                  <Text color={"var(--green)"}>
                    {total}
                    <Box
                      color="gray.500"
                      fontWeight="semibold"
                      fontSize="xs"
                      textTransform="uppercase"
                    >
                      ({totalPct.toFixed(2)}%)
                    </Box>
                  </Text>
                </Td>
                <Td>
                  <Text color={"var(--blue)"}>{formatNumber(average)}</Text>
                </Td>
                <Td>
                  <Text color={"var(--blue)"}>{formatNumber(min)}</Text>
                </Td>
                <Td>
                  <Text color={"var(--blue)"}>{formatNumber(max)}</Text>
                </Td>
              </Tr>
              <Tr>
                <Td colSpan={5}>
                  <Box mt="20px" mb="20px">
                    <LineGraph
                      chart={chart}
                      height={"300px"}
                      title={`Entire Organization: ${serviceName} Arrival Rates, Hour by Hour Comparison`}
                    />
                  </Box>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default ArrivalRatesPerServiceTable;
