import { Box } from "@chakra-ui/react";

import { useSelector } from "react-redux";
import Loader from "../../../components/loader";
import { getReportDescription } from "../../../store/helpers";
import BranchTicketsPerAgent from "../../../components/branches/reports/daily/BranchTicketsPerAgent";
import { REPORT_TYPE_IDS } from "../../../store/constants";
import _ from "lodash";

const DailyBranchTicketsPerAgent = ({
  showFilterForm = true,
  reportIndex = "**REPORT_INDEX**",
}) => {
  const { loading, reportRange, reportsData, branchId } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId = REPORT_TYPE_IDS.daily_branch_tickets_per_agent

  let reports = reportsData.filter((a) => a.reportTypeId === reportTypeId);
  if (branchId) {
    reports = reports.filter((a) => a.branchId === branchId);
  }
  const data = reports.map((a) => a.data);

  const title = `${reportIndex || ""}. Tickets Per Agent`;
  console.log(title);

  return (
    <Box>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <h1>{title}</h1>
          <p>{getReportDescription(reportRange).TicketsByAgents}</p>
          {!_.isEmpty(data) ? (
            <>
              <BranchTicketsPerAgent reportIndex={reportIndex} data={data} />
            </>
          ) : (
            <Box mt={10}>
              <p>No data available in this report</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default DailyBranchTicketsPerAgent;
