import { ACTION_TYPES } from "../actions/types";
import dayjs from "dayjs";
import { REPORT_RANGES } from "../constants";

const initialState = {
  setupStatus: -1, // whether the app is setup or not. -1=not done yet, 0=failed, 1=success
  reportRange: "",

  organizations: [],
  tickets: [],
  services: [],
  servicesSubscriptions: [],
  users: [],
  branches: [],
  reportId: "",

  // filter
  organizationId: "",
  branchId: "",
  dateRange: [
    dayjs().subtract(1, "month").startOf("month").unix(),
    dayjs().subtract(1, "month").endOf("month").unix(),
  ],

  // settings
  hoursPerDay: 8,
  loading: false,
  workWeek: [1, 2, 3, 4, 5], // sunday=0, monday=1, tuesday=2, wednesday=3, thursday=4, friday=5, saturday=6
  showEmptyDataSets: 0, // 1=show , 0=hide,
  reportTypes: [],
  reportsData: [],
  mailingList: {},
  config: {},
  logoData: {},
};

export default function reducerCase(state = initialState, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case ACTION_TYPES.UPDATE_APP_STATUS:
      return {
        ...state,
        appStatus: action.payload,
      };

    case ACTION_TYPES.UPDATE_REPORT_DURATION:
      return {
        ...state,
        reportDuration: action.payload,
      };

    case ACTION_TYPES.GET_REPORT_TYPES:
      return {
        ...state,
        reportTypes: action.payload,
      };

    case ACTION_TYPES.GET_REPORTS_DATA:
      return {
        ...state,
        reportsData: action.payload,
      };

    case ACTION_TYPES.GET_MAILING_LIST:
      return {
        ...state,
        mailingList: action.payload,
      };

    case ACTION_TYPES.GET_CONFIG:
      return {
        ...state,
        config: action.payload,
      };

    case ACTION_TYPES.GET_LOGO_DATA:
      return {
        ...state,
        logoData: action.payload,
      };

    case ACTION_TYPES.UPDATE_REPORT_RANGE:
      return {
        ...state,
        reportRange: action.payload,
      };

    default:
      return state;
  }
}
