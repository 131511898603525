import React from "react";
import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { REPORT_TYPE_IDS } from "../../../store/constants";

import BranchTicketsPerService from "../../../components/branches/reports/daily/BranchTicketsPerService";
import { getReportDescription } from "../../../store/helpers";

const DailyBranchTicketsPerService = ({
  showFilterForm = true,
  reportIndex = "**REPORT_INDEX**",
}) => {
  const { reportRange, reportsData, branchId } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId =  REPORT_TYPE_IDS.daily_branch_tickets_per_service

  const reportTitle = `${reportIndex}. Tickets Per Service`;
  console.log(reportTitle);
  let reports = reportsData.filter((a) => a.reportTypeId === reportTypeId);
  if (branchId) {
    reports = reports.filter((a) => a.branchId === branchId);
  }
  const data = reports.map((a) => a.data);

  return (
    <Box>
      {
        // create a page break if this is not the first report
        Number(reportIndex) !== 1 ? (
          <Box className="forcePageBreak"></Box>
        ) : null
      }
      <Box mb="20px">
        <h1>{reportTitle}</h1>
        <p className="reportDescription">
          {getReportDescription(reportRange).TicketsPerServiceBranches}
        </p>
      </Box>
      {!_.isEmpty(data) ? (
        data.map((a, index) => (
          <BranchTicketsPerService
            key={index}
            branchId={a.branchId}
            reportIndex={reportIndex}
            branchIndex={index + 1}
            data={a.serviceData}
            numServices={a.numServices}
            branchName={a.branchName}
          />
        ))
      ) : (
        <>No data available in this report</>
      )}
    </Box>
  );
};

export default DailyBranchTicketsPerService;
