import { Box, Text } from "@chakra-ui/react";

import AgentServiceTimePerServiceTable from "../../tables/daily/AgentServiceTimePerServiceTable";
const BranchAgentServiceTimePerService = ({ reportIndex, data }) => {
  return (
    <Box>
      {data.map(({ branchName, servicesData, numServices }, branchIndex) => {
        const title = `${reportIndex || ""}.${branchIndex + 1}. ${branchName}`;
        console.log(title);
        return (
          <>
            <h2>{title}</h2>
            <Text>{numServices} services.</Text>
            {servicesData.map((data, index) => {
              return (
                <AgentServiceTimePerServiceTable
                  index={index}
                  serviceData={data}
                  branchIndex={branchIndex}
                  reportIndex={reportIndex}
                  branchName={branchName}
                />
              );
            })}
          </>
        );
      })}
    </Box>
  );
};

export default BranchAgentServiceTimePerService;
