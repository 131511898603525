import React from 'react';
import {
    Box
} from '@chakra-ui/react';
import { useSelector } from "react-redux";

import { REPORT_RANGES, REPORT_TYPE_IDS } from '../../store/constants';

import ServiceTimePerService from "../../components/organization/reports/ServiceTimePerService";
import _ from 'lodash';
import { getReportDescription } from '../../store/helpers';

const ServiceTimePerServicePage = ({
  showFilterForm = true,
  reportIndex = "**REPORT_INDEX**",
}) => {
  const { reportRange, reportsData } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId =
    reportRange === REPORT_RANGES.WEEK
      ? REPORT_TYPE_IDS.weekly_organization_service_time_per_service
      : REPORT_TYPE_IDS.monthly_organization_service_time_per_service;

  const reportTitle = `${reportIndex}. Service Time Per Service`;
  console.log(reportTitle);

  const report = reportsData.find((a) => a.reportTypeId === reportTypeId);
  const { data } = report;
  return (
    <Box>
      <Box mb="20px">
        <h1>{reportTitle}</h1>
        <p>{getReportDescription(reportRange).ServiceTimePerService}</p>
      </Box>
      {!_.isEmpty(data) ? (
        <ServiceTimePerService reportIndex={reportIndex} data={data} />
      ) : null}
    </Box>
  );
};

export default ServiceTimePerServicePage;
  