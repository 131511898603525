import React from "react";
import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";

import {  REPORT_TYPE_IDS } from "../../../store/constants";

import OrganizationServiceTimePerService from "../../../components/organization/reports/daily/OrganizationServiceTimePerService";
import _ from "lodash";
import { getReportDescription } from "../../../store/helpers";

const DailyOrganizationServiceTimePerService = ({
  showFilterForm = true,
  reportIndex = "**REPORT_INDEX**",
}) => {
  const { reportRange, reportsData } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId = REPORT_TYPE_IDS.daily_organization_service_time_per_service

  const reportTitle = `${reportIndex}. Service Time Per Service`;
  console.log(reportTitle);

  const report = reportsData.find((a) => a.reportTypeId === reportTypeId);
  const { data } = report;
  return (
    <Box>
      <Box mb="20px">
        <h1>{reportTitle}</h1>
        <p>{getReportDescription(reportRange).ServiceTimePerService}</p>
      </Box>
      {!_.isEmpty(data) ? (
        <OrganizationServiceTimePerService
          reportIndex={reportIndex}
          data={data}
        />
      ) : null}
    </Box>
  );
};

export default DailyOrganizationServiceTimePerService;
