import React from "react";
import { Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import _ from "lodash";

import OrganizationTicketsPerService from "../../../components/organization/reports/daily/OrganizationTicketsPerService";
import { getReportDescription } from "../../../store/helpers";
import { REPORT_TYPE_IDS } from "../../../store/constants";

const DailyOrganizationTicketsPerService = ({
  showFilterForm = true,
  reportIndex = "**REPORT_INDEX**",
}) => {
  const { reportRange, reportsData } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId = REPORT_TYPE_IDS.daily_organization_tickets_per_service;

  const reportTitle = `${reportIndex}. Tickets Per Service`;
  console.log(reportTitle);

  const report = reportsData.find((a) => a.reportTypeId === reportTypeId);
  const { data } = report;
  return (
    <Box>
      {
        // create a page break if this is not the first report
        Number(reportIndex) !== 1 ? (
          <Box className="forcePageBreak"></Box>
        ) : null
      }
      <Box mb="20px">
        <h1>{reportTitle}</h1>
        <p>{getReportDescription(reportRange).TicketsPerService}</p>
      </Box>
      {!_.isEmpty(data) ? (
        <OrganizationTicketsPerService reportIndex={reportIndex} data={data} />
      ) : null}
    </Box>
  );
};

export default DailyOrganizationTicketsPerService;
