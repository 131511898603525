import React from "react";

import { Box } from "@chakra-ui/react";

import OrganizationTicketsPerServiceTable from "../../tables/daily/OrganizationTicketsPerService";

const OrganizationTicketsPerService = ({
  branchId = null,
  reportIndex,
  branchIndex,
  data,
  numServices = 0,
  branchName = null,
}) => {
  return (
    <Box>
      <OrganizationTicketsPerServiceTable
        branchId={branchId}
        data={data}
        numServices={numServices}
        branchName={branchName}
        reportIndex={reportIndex}
        branchIndex={branchIndex}
      />
    </Box>
  );
};

export default OrganizationTicketsPerService;
