import { Box } from "@chakra-ui/react";

import { useSelector } from "react-redux";
import Loader from "../../../components/loader";
import { getReportDescription } from "../../../store/helpers";
import OrganizationArrivalRatesPerHour from "../../../components/organization/reports/daily/OrganizationArrivalRatesPerHour";
import { REPORT_TYPE_IDS } from "../../../store/constants";
import _ from "lodash";

const DailyOrganizationArrivalRatesPerHour = ({
  showFilterForm = true,
  reportIndex = "**REPORT_INDEX**",
}) => {
  const { tickets, loading, reportRange, reportsData } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId = REPORT_TYPE_IDS.daily_organization_arrival_rates_per_hour;

  const title = `${reportIndex}. Arrival Rates Per Hour`;
  console.log(title);
  const report = reportsData.find((a) => a.reportTypeId === reportTypeId);
  const { data } = report;

  return (
    <Box>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <Box mb="20px">
            <h1>{title}</h1>
            <p>{getReportDescription(reportRange).ArrivalRatesPerHour}</p>
          </Box>
          {!_.isEmpty(data) ? (
            <>
              <OrganizationArrivalRatesPerHour
                reportIndex={reportIndex}
                data={data}
              />
            </>
          ) : (
            <Box mt={10}>
              <p>No data available in this report</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default DailyOrganizationArrivalRatesPerHour;
