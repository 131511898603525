import { combineReducers } from "redux";
import defaultReducer from "./defaultReducer";
import authReducer from "./authReducer";

const reducer = combineReducers({
  defaultReducer,
  authReducer,
});

export default reducer;
