import { Box, Heading } from "@chakra-ui/react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const TicketsPerAgentLineGraph = ({ title, chart }) => {
  return (
    <>
      <Box width="100%" height="200px">
        <ResponsiveContainer>
          <LineChart
            data={chart}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid stroke="#EBEBEB" vertical={false} />
            <XAxis
              dataKey="hour"
              name="Hour"
              axisLine={{ stroke: "var(--darkGrey)", strokeWidth: 1 }}
            />
            <YAxis axisLine={{ stroke: "var(--darkGrey)", strokeWidth: 1 }} />
            <Tooltip />
            <Legend width="100%" />
            <Line
              type="monotone"
              strokeWidth={3}
              dataKey="total"
              name="Total"
              stroke={"var(--blue)"}
              dot={{ fill: "var(--blue)", r: 2 }}
              activeDot={{ r: 4 }}
              animationDuration={0}
            />
            <Line
              type="monotone"
              strokeWidth={3}
              dataKey="completed"
              name="Completed"
              stroke={"var(--green)"}
              dot={{ fill: "var(--green)", r: 2 }}
              activeDot={{ r: 4 }}
              animationDuration={0}
            />
            <Line
              type="monotone"
              strokeWidth={3}
              dataKey="no_show"
              name="No Show"
              stroke={"var(--red)"}
              dot={{ fill: "var(--red)", r: 2 }}
              activeDot={{ r: 4 }}
              animationDuration={0}
            />
            <Line
              type="monotone"
              strokeWidth={3}
              dataKey="unattended"
              name="Unattended"
              stroke={"var(--orange)"}
              dot={{ fill: "var(--orange)", r: 2 }}
              activeDot={{ r: 4 }}
              animationDuration={0}
            />
          </LineChart>
        </ResponsiveContainer>
        <Heading
          size="sm"
          fontWeight={400}
          fontSize="14px"
          color="#535353"
          mb="20px"
          textAlign="center"
        >
          {title}
        </Heading>
      </Box>
    </>
  );
};

export default TicketsPerAgentLineGraph;
