import React from 'react';
import {
    Box
} from '@chakra-ui/react';
import { useSelector } from "react-redux";
import _ from 'lodash';

import TicketsPerService from "../../components/organization/reports/TicketsPerService";
import { getReportDescription } from '../../store/helpers';
import { REPORT_RANGES, REPORT_TYPE_IDS } from '../../store/constants';

const TicketsPerServicePage = ({
  showFilterForm = true,
  reportIndex = "**REPORT_INDEX**",
}) => {
  const { reportRange, reportsData } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId =
    reportRange === REPORT_RANGES.WEEK
      ? REPORT_TYPE_IDS.weekly_organization_tickets_per_service
      : REPORT_TYPE_IDS.monthly_organization_tickets_per_service;

  const reportTitle = `${reportIndex}. Tickets Per Service`;
  console.log(reportTitle);

  const report = reportsData.find((a) => a.reportTypeId === reportTypeId);
  const { data } = report;
  return (
    <Box>
      {
        // create a page break if this is not the first report
        Number(reportIndex) !== 1 ? (
          <Box className="forcePageBreak"></Box>
        ) : null
      }
      <Box mb="20px">
        <h1>{reportTitle}</h1>
        <p>{getReportDescription(reportRange).TicketsPerService}</p>
      </Box>
      {!_.isEmpty(data) ? (
        <TicketsPerService reportIndex={reportIndex} data={data} />
      ) : null}
    </Box>
  );
};

export default TicketsPerServicePage;
  