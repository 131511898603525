import LineGraph from "../../LineGraph";
import ArrivalRatesPerDayTable from "../tables/ArrivalRatesPerDayTable";

import { Box, Divider } from "@chakra-ui/react";
import TimeOfDayChart from "../../TimeOfDayChart";

const ArrivalRatesPerHour = ({ reportIndex = 1, data: reportData }) => {
  const title = `${reportIndex}.1. Entire Organization`;
  console.log(title);

  console.log(reportData, "reportData shape");
  const { average, min, max, total, chart, timeOfDayChart } = reportData;

  return (
    <>
      <Box>
        <Box className="reportContainer" mt="20px">
          <ArrivalRatesPerDayTable
            average={average}
            min={min}
            max={max}
            total={total}
            title={title}
          />

          <LineGraph
            chart={chart}
            height={"300px"}
            title={"Entire Organization Arrival Rates, Day by Day Comparison"}
          />
        </Box>
        <Divider />
        <Box mt="20px" mb="20px" className="reportContainer">
          <TimeOfDayChart {...timeOfDayChart} title={"Time of Day Chart"} />
        </Box>
      </Box>
    </>
  );
};

export default ArrivalRatesPerHour;
