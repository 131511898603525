import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import throttle from 'lodash/throttle';

import store from './store/index.js';
import App from './App';
import { saveState } from './store/LocalStorage';


store.subscribe(throttle(() => {
  const defaultReducer = store.getState().defaultReducer;
  const authReducer = store.getState().authReducer;
  saveState({ defaultReducer, authReducer });
}, 1000));

const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
