import { Box } from "@chakra-ui/react";
import BranchServicesTable from "../tables/BranchServicesTable";

import { ARRIVAL_RATES_FREQUENCIES } from "../../../store/constants";


const BranchServices = ({ reportIndex, data }) => {

  return (
    <Box>
      {data.map(({ branchName, servicesData }, branchIndex) => {
        const title = `${reportIndex || ""}.${branchIndex + 1}. ${branchName}`;
        console.log(title);
        return (
          <>
            <h2>{title}</h2>
            {servicesData.map((data, index) => {
              return (
                <BranchServicesTable
                  index={index}
                  data={data}
                  branchIndex={branchIndex}
                  reportIndex={reportIndex}
                  branchName={branchName}
                  frequency={ARRIVAL_RATES_FREQUENCIES.DAY}
                />
              );
            })}
          </>
        );
      })}
    </Box>
  );
};

export default BranchServices;
