import { Box } from "@chakra-ui/react";
import _ from "lodash";
import { useSelector } from "react-redux";
import TicketsByBranchReport from "../../components/organization/reports/TicketsByBranch";
import Loader from "../../components/loader";
import { getReportDescription } from "../../store/helpers";
import { REPORT_RANGES, REPORT_TYPE_IDS } from "../../store/constants";

const TicketsPerBranch = ({
  showFilterForm = true,
  reportIndex = "**REPORT_INDEX**",
}) => {
  const { loading, reportRange, reportsData } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId =
    reportRange === REPORT_RANGES.WEEK
      ? REPORT_TYPE_IDS.weekly_organization_tickets_per_branch
      : REPORT_TYPE_IDS.monthly_organization_tickets_per_branch;

  const title = `${reportIndex}. Tickets Per Branch`;
  console.log(title);

  const report = reportsData.find((a) => a.reportTypeId === reportTypeId);
  const { data } = report;

  return (
    <Box>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <h1>{title}</h1>
          <p>{getReportDescription(reportRange).TicketsByBranch}</p>
          {!_.isEmpty(data) ? (
            <>
              <TicketsByBranchReport reportIndex={reportIndex} data={data} />
            </>
          ) : (
            <Box mt={10}>
              <p>No data available in this search</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default TicketsPerBranch;
