import {
  Box,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { formatNumber } from "../../../../store/helpers";

const AgentServicesTableRows = ({ data, totalServiceData }) => {
  return (
    <>
      {data.map((agentData, index) => {
        const { total } = agentData;
        let totalPct = (total / totalServiceData) * 100 || 0;
        return (
          <Tr>
            <Td>{index + 1}</Td>
            <Td>{agentData.agentName}</Td>
            <Td borderRight="1px solid #dadce0">
              {" "}
              <Text color={"var(--green)"}>
                {formatNumber(agentData.total)}
                <Box
                  color="gray.500"
                  fontWeight="semibold"
                  fontSize="xs"
                  textTransform="uppercase"
                >
                  ({totalPct.toFixed(2)}%)
                </Box>
              </Text>
            </Td>
            <Td borderRight="1px solid #dadce0">
              {formatNumber(agentData.average)}
            </Td>
            <Td>{formatNumber(agentData.min)}</Td>
            <Td>{formatNumber(agentData.max)}</Td>
            <Td>
              {" "}
              <Text color={"var(--green)"}>
                {formatNumber(agentData.numCompleted)}
                <Box
                  color="gray.500"
                  fontWeight="semibold"
                  fontSize="xs"
                  textTransform="uppercase"
                >
                  ({agentData.pctCompleted.toFixed(2)}%)
                </Box>
              </Text>
            </Td>

            <Td>
              {" "}
              <Text color={"var(--red)"}>
                {formatNumber(agentData.numNoShow)}
                <Box
                  color="gray.500"
                  fontWeight="semibold"
                  fontSize="xs"
                  textTransform="uppercase"
                >
                  ({agentData.pctNoShow.toFixed(2)}%)
                </Box>
              </Text>
            </Td>

            <Td>
              {" "}
              <Text color={"var(--red)"}>
                {formatNumber(agentData.numUnattended)}
                <Box
                  color="gray.500"
                  fontWeight="semibold"
                  fontSize="xs"
                  textTransform="uppercase"
                >
                  ({agentData.pctUnattended.toFixed(2)}%)
                </Box>
              </Text>
            </Td>
          </Tr>
        );
      })}
    </>
  );
};
const AgentTicketsPerServiceTable = ({
  serviceData,
  branchName,
  reportIndex,
  branchIndex,
  index,
}) => {
  const [maxWidth, setMaxWidth] = useState(0);
  useEffect(() => {
    const calculateSecondColumnWidth = (table) => {
      const secondColumn = table.querySelector("tr > td:nth-child(2)");
      if (secondColumn) {
        setMaxWidth((prevMaxWidth) =>
          Math.max(prevMaxWidth, secondColumn.offsetWidth)
        );
      }
    };
    document.querySelectorAll("table").forEach((table) => {
      calculateSecondColumnWidth(table);
    });
  }, []);

  useEffect(() => {
    document.querySelectorAll("table").forEach((table) => {
      table.querySelectorAll("tr > td:nth-child(2)").forEach((secondColumn) => {
        secondColumn.style.width = `${maxWidth}px`;
      });
    });
  }, [maxWidth]);

  const { serviceName, agentsData, totalServiceData } = serviceData;

  const title = `${reportIndex || ""}.${branchIndex + 1}.${
    index + 1
  }. ${serviceName}`;
  console.log(title);

  return (
    <Box>
      <h3>{title}</h3>
      {!totalServiceData ? (
        <Text fontSize="sm">No data available for this report</Text>
      ) : (
        <></>
      )}
      <Tag>
        <p className="branchTag">{branchName} Branch</p>
      </Tag>

      {totalServiceData ? (
        <Table className="stripedTable" size="sm">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Agent Name</Th>
              <Th borderRight="1px solid #dadce0">Total</Th>
              <Th borderRight="1px solid #dadce0">Average</Th>
              <Th>Min</Th>
              <Th>Max</Th>
              <Th>Completed</Th>
              <Th>No Show</Th>
              <Th>Unattended</Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              <AgentServicesTableRows
                totalServiceData={totalServiceData}
                data={agentsData}
              />
            }
          </Tbody>
        </Table>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default AgentTicketsPerServiceTable;
