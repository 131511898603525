// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

@media print {

    /* hide sidebar */
    .sidebar {
        display: none;
    }
    /* hide filter */
    .filterForm {
        display: none;
    }
    /* enlarge content area */
    .content {
        margin-left: 0;
    }

    /* table */
    .stripedTable thead th {
        padding-left: 5px!important;
        padding-right: 5px!important;

    }

    .stripedTable tbody td {
        padding-left: 5px!important;
        padding-right: 5px!important;
    }
   

    .reportContainer {
        page-break-inside: avoid;
    }

    .forcePageBreak {
        page-break-before: always;
    }

    @page {
        size: A6;
        orientation: portrait;
        
        margin-top: 15mm;
        margin-bottom: 25mm;
        margin-left: 10mm;
        margin-right: 10mm;
    
        @top-center {
            content: "Averly Queue Management Report";
        }

        @bottom-right {
            counter-increment: page;
            counter-reset: page 1;
            content: "page " counter(page);
        }
        
    }
    

}`, "",{"version":3,"sources":["webpack://./src/assets/css/Print.css"],"names":[],"mappings":";;AAEA;;IAEI,iBAAiB;IACjB;QACI,aAAa;IACjB;IACA,gBAAgB;IAChB;QACI,aAAa;IACjB;IACA,yBAAyB;IACzB;QACI,cAAc;IAClB;;IAEA,UAAU;IACV;QACI,2BAA2B;QAC3B,4BAA4B;;IAEhC;;IAEA;QACI,2BAA2B;QAC3B,4BAA4B;IAChC;;;IAGA;QACI,wBAAwB;IAC5B;;IAEA;QACI,yBAAyB;IAC7B;;IAEA;QACI,QAAQ;QACR,qBAAqB;;QAErB,gBAAgB;QAChB,mBAAmB;QACnB,iBAAiB;QACjB,kBAAkB;;QAElB;YACI,yCAAyC;QAC7C;;QAEA;YACI,uBAAuB;YACvB,qBAAqB;YACrB,8BAA8B;QAClC;;IAEJ;;;AAGJ","sourcesContent":["\n\n@media print {\n\n    /* hide sidebar */\n    .sidebar {\n        display: none;\n    }\n    /* hide filter */\n    .filterForm {\n        display: none;\n    }\n    /* enlarge content area */\n    .content {\n        margin-left: 0;\n    }\n\n    /* table */\n    .stripedTable thead th {\n        padding-left: 5px!important;\n        padding-right: 5px!important;\n\n    }\n\n    .stripedTable tbody td {\n        padding-left: 5px!important;\n        padding-right: 5px!important;\n    }\n   \n\n    .reportContainer {\n        page-break-inside: avoid;\n    }\n\n    .forcePageBreak {\n        page-break-before: always;\n    }\n\n    @page {\n        size: A6;\n        orientation: portrait;\n        \n        margin-top: 15mm;\n        margin-bottom: 25mm;\n        margin-left: 10mm;\n        margin-right: 10mm;\n    \n        @top-center {\n            content: \"Averly Queue Management Report\";\n        }\n\n        @bottom-right {\n            counter-increment: page;\n            counter-reset: page 1;\n            content: \"page \" counter(page);\n        }\n        \n    }\n    \n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
