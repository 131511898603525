import { Box, Center, Heading } from "@chakra-ui/react";
import { HeatMapGrid } from "react-grid-heatmap";
import { useSelector } from "react-redux";

const ArrivalHeatmap = ({ arrivalTimes, title }) => {
  const { workWeek } = useSelector((store) => store.defaultReducer);
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const xLabels = [];
  workWeek.forEach((day) => {
    xLabels.push(daysOfWeek[day]);
  });

  const startHour = 7;
  const endHour = 18;

  const yLabels = [...Array(endHour - startHour + 1).keys()].map(
    (i) => `${(i + startHour) % 12 || 12} ${i + startHour < 12 ? "am" : "pm"}`
  );

  const days = Object.fromEntries(
    Array.from({ length: 7 }, (_, index) => [String(index), []])
  );

  for (const arrivalTime of arrivalTimes) {
    days[new Date(arrivalTime).getDay()].push(arrivalTime);
  }

  const daysFrequencies = Array.from({ length: 7 }).reduce((acc, _, day) => {
    const days = arrivalTimes.filter((date) => new Date(date).getDay() === day);
    const uniqueDays = new Set(
      days.map((date) => new Date(date).toDateString())
    );
    acc[day] = uniqueDays.size;
    return acc;
  }, {});

  let data = Array.from({ length: endHour + 1 }).reduce((acc, _, i) => {
    acc[i] = [];
    return acc;
  }, []);

  for (let i = startHour; i <= endHour; i++) {
    for (let j = 0; j < workWeek.length; j++) {
      const dayIndex = workWeek[j];
      //the inner arrays need to always start at index 0
      data[i][dayIndex - Math.abs(0 - workWeek[0])] = Math.round(
        days[dayIndex].filter((day) => new Date(day).getHours() === i).length /
          (daysFrequencies[dayIndex] === 0 ? 1 : daysFrequencies[dayIndex]) ///avoid dividing by 0
      );
    }
  }

  title = title || "Time of Day Chart";

  return (
    <Center>
      <Box width="80%" alignItems="center">
        <Heading size="md" mb="20px" textAlign="center">
          {title}
        </Heading>
        <HeatMapGrid
          data={data}
          xLabels={xLabels}
          yLabels={yLabels}
          // Render cell with tooltip
          cellRender={(x, y, value) => (
            <div title={`Pos(${x}, ${y}) = ${value}`}>{value}</div>
          )}
          xLabelsStyle={(index) => ({
            color: index % 2 ? "transparent" : "#777",
            fontSize: ".8rem",
          })}
          yLabelsStyle={() => ({
            fontSize: ".7rem",
            textTransform: "uppercase",
            color: "#777",
          })}
          cellStyle={(_x, _y, ratio) => ({
            background: `rgb(26,115,232, ${ratio})`,
            fontSize: ".8rem",
            color: `rgb(0, 0, 0, ${ratio / 2 + 0.4})`,
          })}
          cellHeight="2rem"
          xLabelsPos="bottom"
          yLabelsPos="right"
        />
      </Box>
    </Center>
  );
};

export default ArrivalHeatmap;
