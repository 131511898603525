import { Box } from "@chakra-ui/react";

import { useSelector } from "react-redux";
import Loader from "../../components/loader";
import { getReportDescription } from "../../store/helpers";
import ArrivalRatesPerHourReport from "../../components/organization/reports/ArrivalRatesPerHour";
import { REPORT_RANGES, REPORT_TYPE_IDS } from "../../store/constants";
import _ from "lodash";

const ArrivalRatesPerHour = ({
  showFilterForm = true,
  reportIndex = "**REPORT_INDEX**",
}) => {
  const { tickets, loading, reportRange, reportsData } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId =
    reportRange === REPORT_RANGES.WEEK
      ? REPORT_TYPE_IDS.weekly_organization_arrival_rates_per_hour
      : REPORT_TYPE_IDS.monthly_organization_arrival_rates_per_hour;

  const title = `${reportIndex}. Arrival Rates Per Hour`;
  console.log(title);
  const report = reportsData.find((a) => a.reportTypeId === reportTypeId);
  const { data } = report;

  return (
    <Box>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <Box mb="20px">
            <h1>{title}</h1>
            <p>{getReportDescription(reportRange).ArrivalRatesPerHour}</p>
          </Box>
          {!_.isEmpty(data) ? (
            <>
              <ArrivalRatesPerHourReport
                reportIndex={reportIndex}
                data={data}
              />
            </>
          ) : (
            <Box mt={10}>
              <p>No data available in this search</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ArrivalRatesPerHour;
