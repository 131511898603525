export const version = "1.8.22";
export const ARRIVAL_RATES_FREQUENCIES = Object.freeze({ HOUR: "0", DAY: "1" });
export const LEVELS = Object.freeze({ ORGANIZATION: "0", BRANCH: "1" });
export const completed = 3;
export const noShow = 2;
export const transferred = 1;
export const TICKET_STATUS = Object.freeze({
  COMPLETED: 3,
  NO_SHOW: 2,
  TRANSFERRED: 1,
});

export const REPORT_RANGES = Object.freeze({
  MONTH: "month",
  WEEK: "week",
  DAY: "day",
});

export const APP_STATUSES = Object.freeze({
  LOGGED_IN: 1,
  PEDING_LOGIN: 2,
  LOGGED_OUT: 0,
});

export const navLinks = Object.freeze([
  {
    name: "month",
    links: [
      {
        name: "Reports",
        links: [
          {
            name: "Monthly - Full Report",
            link: "/monthly/reports/full-report/",
          },
          {
            name: "Monthly - Arrival Rates & Waiting Times",
            link: "/monthly/reports/arrival-times/",
          },
          {
            name: "Monthly - Agent Performance",
            link: "/monthly/reports/agent-performance/",
          },
          {
            name: "Monthly - Ticket Management",
            link: "/monthly/reports/ticket-management/",
          },
        ],
      },
      {
        name: "Entire Organization",
        links: [
          {
            name: "Tickets",
            link: "/monthly/organization/tickets/",
          },
          {
            name: "Tickets Per Service",
            link: "/monthly/organization/tickets-per-service/",
          },
          {
            name: "Service Time Per Service",
            link: "/monthly/organization/service-tim-per-service/",
          },
          {
            name: "Arrival Rates Per Hour",
            link: "/monthly/organization/arrival-rates-per-hour/",
          },
          {
            name: "Arrival Rates Per Day",
            link: "/monthly/organization/arrival-rates-per-day/",
          },

          {
            name: "Arrival Rates Per Service",
            link: "/monthly/organization/arrival-rates-per-service/",
          },
          {
            name: "Tickets Per Branch",
            link: "/monthly/organization/tickets-per-branch/",
          },
        ],
      },
      {
        name: "Arrival Rates",
        links: [
          {
            name: "Arrival Rates Per Hour",
            link: "/monthly/branches/arrival-rates-per-hour/",
          },
          {
            name: "Arrival Rates Per Day",
            link: "/monthly/branches/arrival-rates-per-day/",
          },
          {
            name: "Arrival Rates Per Service",
            link: "/monthly/branches/arrival-rates-per-service/",
          },
        ],
      },
      {
        name: "Service Times",
        links: [
          {
            name: "Service Time Per Service",
            link: "/monthly/branches/service-tim-per-service/",
          },
          {
            name: "Agent Service Time Per Service",
            link: "/monthly/branches/agent-service-time-per-service/",
          },
        ],
      },
      {
        name: "Tickets",
        links: [
          {
            name: "Tickets Per Service",
            link: "/monthly/branches/tickets-per-service/",
          },
          {
            name: "Tickets Per Agent",
            link: "/monthly/branches/tickets-per-agents/",
          },
          {
            name: "Agent Tickets Per Service",
            link: "/monthly/branches/agent-tickets-per-service/",
          },
        ],
      },
    ],
  },
  {
    name: "week",
    links: [
      {
        name: "Entire Organization",
        links: [
          {
            name: "Tickets",
            link: "/weekly/organization/tickets/",
          },
          {
            name: "Tickets Per Service",
            link: "/weekly/organization/tickets-per-service/",
          },
          {
            name: "Service Time Per Service",
            link: "/weekly/organization/service-tim-per-service/",
          },
          {
            name: "Arrival Rates Per Hour",
            link: "/weekly/organization/arrival-rates-per-hour/",
          },
          {
            name: "Arrival Rates Per Day",
            link: "/weekly/organization/arrival-rates-per-day/",
          },

          {
            name: "Arrival Rates Per Service",
            link: "/weekly/organization/arrival-rates-per-service/",
          },
          {
            name: "Tickets Per Branch",
            link: "/weekly/organization/tickets-per-branch/",
          },
        ],
      },
      {
        name: "Arrival Rates",
        links: [
          {
            name: "Arrival Rates Per Hour",
            link: "/weekly/branches/arrival-rates-per-hour/",
          },
          {
            name: "Arrival Rates Per Day",
            link: "/weekly/branches/arrival-rates-per-day/",
          },
          {
            name: "Arrival Rates Per Service",
            link: "/weekly/branches/arrival-rates-per-service/",
          },
        ],
      },
      {
        name: "Service Times",
        links: [
          {
            name: "Service Time Per Service",
            link: "/weekly/branches/service-tim-per-service/",
          },
          {
            name: "Agent Service Time Per Service",
            link: "/weekly/branches/agent-service-time-per-service/",
          },
        ],
      },
      {
        name: "Tickets",
        links: [
          {
            name: "Tickets Per Service",
            link: "/weekly/branches/tickets-per-service/",
          },
          {
            name: "Tickets Per Agent",
            link: "/weekly/branches/tickets-per-agents/",
          },
          {
            name: "Agent Tickets Per Service",
            link: "/weekly/branches/agent-tickets-per-service/",
          },
        ],
      },
    ],
  },
  {
    name: "day",
    links: [
      {
        name: "Entire Organization",
        links: [],
      },
      {
        name: "Arrival Rates",
        links: [],
      },
      {
        name: "Service Times",
        links: [],
      },
      {
        name: "Tickets",
        links: [],
      },
    ],
  },
]);

export const REPORT_TYPE_IDS = Object.freeze({
  weekly_branch_arrival_rates_per_service:
    "61760a8d-d94c-4c8a-b15d-05fb5ad95f80",
  weekly_organization_arrival_rates_per_hour:
    "0cd9eb6d-45e2-4c8d-abe0-7faf04b72ea0",
  weekly_organization_arrival_rates_per_day:
    "06cbe100-66ee-497f-9ffd-50239a0b722d",
  monthly_organization_arrival_rates_per_day:
    "1df33d3e-0e9b-4350-87aa-408f02284fcc",
  weekly_branch_tickets_per_agent: "2ed97bf9-1299-45a9-aa2f-1d134663d751",
  monthly_branch_agent_tickets_per_service:
    "4fde6bfb-c16d-4f4f-9d5e-06745e5071d1",
  monthly_organization_service_time_per_service:
    "2f304820-f165-4f05-91ac-b6de758efd3c",
  monthly_branch_agent_service_time_per_service:
    "1edbd63e-e1ae-4747-b3ab-647326e184a6",
  weekly_organization_tickets_per_branch:
    "b006daa2-59fb-48bc-9f3e-457042c3c2ff",
  monthly_organization_tickets: "961d2efc-d448-4880-9e53-ee29a2610e00",
  weekly_branch_service_time_per_service:
    "23718f26-b0b3-440d-811b-fa8678446d5e",
  monthly_branch_arrival_rates_per_service:
    "21ae1a4a-25e5-439f-bcb8-a5a7ca5c578c",
  weekly_organization_arrival_rates_per_service:
    "548fe680-bb8c-4ca6-bb47-8141fe288bda",
  monthly_organization_tickets_per_service:
    "e60221c4-ec45-4c8c-8a11-c75f90f384d6",
  weekly_organization_service_time_per_service:
    "cddc1544-e8bf-4327-9804-562831196172",
  weekly_branch_agent_tickets_per_service:
    "628ae68a-0336-43cf-9596-a87d0c5cdc1f",
  monthly_organization_arrival_rates_per_hour:
    "e4a3bb60-ea8d-43dc-aafa-5b1b9b6eb020",
  monthly_branch_arrival_rates_per_day: "b7bfb700-0843-486a-93cc-2a98f1ead9cc",
  monthly_branch_tickets_per_service: "71a30505-d174-4da6-9807-7e03210077c3",
  weekly_branch_agent_service_time_per_service:
    "4fc20200-1bdf-4ce4-aeb4-5867d14f8fa4",
  monthly_branch_arrival_rates_per_hour: "a3361862-cafe-459b-9f76-d21835d68c09",
  weekly_branch_arrival_rates_per_hour: "e4af3c3d-c6bc-499b-940c-85c4cf9cc307",
  weekly_organization_tickets: "7c324784-91b2-4f44-ab17-1adbc6568baa",
  monthly_branch_service_time_per_service:
    "b0d6bbf5-b663-497c-8af2-8531f7a2f0da",
  monthly_organization_arrival_rates_per_service:
    "f7c61497-6220-4164-ac00-80cbf882a937",
  weekly_branch_arrival_rates_per_day: "777e9bb0-f6cc-499f-9ee9-48fc64a19932",
  monthly_organization_tickets_per_branch:
    "e545deea-30bd-49f1-a645-f68db90905ae",
  weekly_organization_tickets_per_service:
    "d17cde14-1f9c-442e-a724-d030917a56e9",
  monthly_branch_tickets_per_agent: "c8c9f2e9-1740-4251-9c0c-c50aa4e28912",
  weekly_branch_tickets_per_service: "fc90a593-cb3b-4f99-8ec9-f530dfc62860",

  monthly_branch_customers_in_branch_per_hour:
    "6dacd9ae-1c7d-4ec1-b13b-4be2d63d3b7b",
  weekly_branch_customers_in_branch_per_hour:
    "57efa6cc-7987-46fa-b348-ad55f1102e1b",

  //daily reports
  daily_organization_tickets: "13e7a765-ee66-4d7a-92a2-94fd8c564031",
  daily_branch_tickets_per_service: "7f28f281-6883-483b-843b-8d7ccdef7b2e",
  daily_branch_agent_service_time_per_service:
    "9cdfb9ba-dd8b-4e99-a8a4-82b8baa1d140",
  daily_organization_tickets_per_service:
    "2efc9e81-a2fe-4ec5-9d2c-cee6ed89c281",
  daily_branch_arrival_rates_per_service:
    "4b9515c9-9893-45a7-926e-c410c17ce814",
  daily_branch_tickets_per_agent: "7f5afc24-71f1-4ce7-9a3c-2b11ca452184",
  daily_branch_arrival_rates_per_hour: "8b48f09e-6ba6-4411-abaa-c6e5c761f91f",
  daily_organization_service_time_per_service:
    "8c0ad869-112d-4d98-90cd-bdeb611827f5",
  daily_branch_service_time_per_service: "80e839f9-5dfc-4bd8-ac24-7e3b30d59dba",
  daily_organization_arrival_rates_per_service:
    "7c748d45-dc77-4872-8142-7373c5a82dd1",
  daily_branch_agent_tickets_per_service:
    "d12b06aa-22e3-4afb-843c-5749455aed77",
  daily_organization_tickets_per_branch: "b5dd1d8f-7248-4ca7-99e5-96f02e2f442d",
  daily_organization_arrival_rates_per_hour:
    "edff19c6-c031-440b-826c-e14848584916",
  daily_branch_customers_in_branch_per_hour:
    "4e966b00-0e0a-489e-ab7a-a952f7e0b3dc",
});
