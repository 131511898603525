import { Box } from "@chakra-ui/react";
import BranchServices from "../../components/branches/reports/BranchServices";
import {
  ARRIVAL_RATES_FREQUENCIES,
  REPORT_RANGES,
  REPORT_TYPE_IDS,
} from "../../store/constants";
import { useSelector } from "react-redux";
import Loader from "../../components/loader";
import { getReportDescription } from "../../store/helpers";
import _ from "lodash";

const ArrivalRatesPerService = ({
  showFilterForm = true,
  reportIndex = "**REPORT_INDEX**",
}) => {
  const { loading, reportRange, reportsData, branchId } = useSelector(
    (state) => state.defaultReducer
  );
  const reportTypeId =
    reportRange === REPORT_RANGES.WEEK
      ? REPORT_TYPE_IDS.weekly_branch_arrival_rates_per_service
      : REPORT_TYPE_IDS.monthly_branch_arrival_rates_per_service;

  const title = `${reportIndex || ""}. Arrival Rates Per Service`;
  console.log(title);

  let reports = reportsData.filter((a) => a.reportTypeId === reportTypeId);
  if (branchId) {
    reports = reports.filter((a) => a.branchId === branchId);
  }
  const data = reports.map((a) => a.data);

  return (
    <Box>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <h1>{title}</h1>
          <p>
            {getReportDescription(reportRange).BranchesArrivalRatesPerService}
          </p>
          {!_.isEmpty(data) ? (
            <>
              <BranchServices
                frequency={ARRIVAL_RATES_FREQUENCIES.DAY}
                reportIndex={reportIndex}
                data={data}
              />
            </>
          ) : (
            <Box mt={10}>
              <p>No data available in this search</p>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ArrivalRatesPerService;
