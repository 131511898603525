import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

import { formatNumber } from "../../../store/helpers";
import { useEffect, useState } from "react";
import LineGraph from "../../LineGraph";
import TimeOfDayChart from "../../TimeOfDayChart";

const ArrivalRatesPerHourTable = ({ data, index }) => {
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    const calculateSecondColumnWidth = (table) => {
      const secondColumn = table.querySelector("tr > td:nth-child(2)");
      if (secondColumn) {
        setMaxWidth((prevMaxWidth) =>
          Math.max(prevMaxWidth, secondColumn.offsetWidth)
        );
      }
    };
    document.querySelectorAll("table").forEach((table) => {
      calculateSecondColumnWidth(table);
    });
  }, []);

  useEffect(() => {
    document.querySelectorAll("table").forEach((table) => {
      table.querySelectorAll("tr > td:nth-child(2)").forEach((secondColumn) => {
        secondColumn.style.width = `${maxWidth}px`;
      });
    });
  }, [maxWidth]);

  const { total, average, min, max, branchName, chart, timeOfDayChart } = data;

  return (
    <>
      <Box>
        <Table className="stripedTable" size="sm">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th borderRight="1px solid #dadce0">Total Visitors</Th>
              <Th borderRight="1px solid #dadce0">Average</Th>
              <Th>Min</Th>
              <Th>Max</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>{index + 1}</Td>
              <Td borderRight="1px solid #dadce0">{formatNumber(total)}</Td>
              <Td borderRight="1px solid #dadce0">{formatNumber(average)}</Td>
              <Td>{formatNumber(min)}</Td>
              <Td>{formatNumber(max)}</Td>
            </Tr>
            <Tr>
              <Td colSpan={5}>
                <Box mt="20px" mb="20px">
                  <LineGraph
                    chart={chart}
                    title={`${branchName} Arrival rates per day, Day by Day Comparison`}
                  />
                  <Box mt={10}>
                    <TimeOfDayChart
                      {...timeOfDayChart}
                      title={"Time of Day Chart"}
                    />
                  </Box>
                </Box>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </>
  );
};

export default ArrivalRatesPerHourTable;
