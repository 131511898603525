import { Box, Table, Thead, Tbody, Tr, Th, Td, Text } from "@chakra-ui/react";
import { useEffect, useId, useState } from "react";
import { formatNumber } from "../../../../store/helpers";

const OrganizationArrivalRatesPerHourTable = ({
  average,
  min,
  max,
  total,
  title,
}) => {
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    const calculateSecondColumnWidth = (table) => {
      const secondColumn = table.querySelector("tr > td:nth-child(2)");
      if (secondColumn) {
        setMaxWidth((prevMaxWidth) =>
          Math.max(prevMaxWidth, secondColumn.offsetWidth)
        );
      }
    };
    document.querySelectorAll("table").forEach((table) => {
      calculateSecondColumnWidth(table);
    });
  }, []);

  useEffect(() => {
    document.querySelectorAll("table").forEach((table) => {
      table.querySelectorAll("tr > td:nth-child(2)").forEach((secondColumn) => {
        secondColumn.style.width = `${maxWidth}px`;
      });
    });
  }, [maxWidth]);
  const id = useId();

  return (
    <Box mt={10}>
      <h3>{title}</h3>
      <Box>
        <Table className="stripedTable" size="sm">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th borderRight="1px solid #dadce0">Total Visitors</Th>
              <Th borderRight="1px solid #dadce0">Average</Th>
              <Th>Min</Th>
              <Th>Max</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr key={id}>
              <Td>1</Td>
              <Td borderRight="1px solid #dadce0" color={"var(--darkGray)"}>
                {formatNumber(total)}
              </Td>
              <Td borderRight="1px solid #dadce0">
                <Text color={"var(--blue)"}>{formatNumber(average)}</Text>
              </Td>
              <Td>
                <Text color={"var(--blue)"}>{formatNumber(min)}</Text>
              </Td>
              <Td>
                <Text color={"var(--blue)"}>{formatNumber(max)}</Text>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default OrganizationArrivalRatesPerHourTable;
