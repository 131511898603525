import { Box, Center, Heading } from "@chakra-ui/react";
import { HeatMapGrid } from "react-grid-heatmap";

const TimeOfDayChart = ({ data, xLabels, yLabels, title }) => {

  return (
    <Center>
      <Box width="80%" alignItems="center">
        <Heading size="md" mb="20px" textAlign="center">
          {title}
        </Heading>
        <HeatMapGrid
          data={data}
          xLabels={xLabels}
          yLabels={yLabels}
          // Render cell with tooltip
          cellRender={(x, y, value) => (
            <div title={`Pos(${x}, ${y}) = ${value}`}>{value}</div>
          )}
          xLabelsStyle={(index) => ({
            color: index % 2 ? "transparent" : "#777",
            fontSize: ".8rem",
          })}
          yLabelsStyle={() => ({
            fontSize: ".7rem",
            textTransform: "uppercase",
            color: "#777",
          })}
          cellStyle={(_x, _y, ratio) => ({
            background: `rgb(26,115,232, ${ratio})`,
            fontSize: ".8rem",
            color: `rgb(0, 0, 0, ${ratio / 2 + 0.4})`,
          })}
          cellHeight="2rem"
          xLabelsPos="bottom"
          yLabelsPos="right"
        />
      </Box>
    </Center>
  );
};

export default TimeOfDayChart;
