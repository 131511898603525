import _ from "lodash";
import ArrivalRatesPerServiceTable from "../../tables/daily/OrganizationArrivalRatesPerServiceTable";

const OrganizationArrivalRatesPerService = ({ reportIndex, data }) => {
  return (
    <>
      {data.map((serviceData, index) => {
        return (
          <>
            <ArrivalRatesPerServiceTable
              index={index}
              reportIndex={reportIndex}
              serviceData={serviceData}
            />
          </>
        );
      })}
    </>
  );
};

export default OrganizationArrivalRatesPerService;
