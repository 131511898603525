import { Box, Heading } from "@chakra-ui/react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const AgentServiceTimePerServiceLineGraph = ({ chart, title, height }) => {
  return (
    <>
      <Box width="100%" h={height || "300px"}>
        <ResponsiveContainer>
          <LineChart
            data={chart}
            margin={{
              top: 0,
              right: 10,
              left: -10,
              bottom: 15,
            }}
          >
            <CartesianGrid stroke="#EBEBEB" vertical={false} />
            <XAxis
              dataKey="hour"
              name="Hour"
              axisLine={{ stroke: "var(--darkGrey)", strokeWidth: 1 }}
            />
            <YAxis axisLine={{ stroke: "var(--darkGrey)", strokeWidth: 1 }} />
            <Tooltip />
            <Legend width="100%" />
            <Line
              type="monotone"
              strokeWidth={3}
              dataKey="hourlyAverage"
              name="Average Session Time"
              stroke={"var(--blue)"}
              dot={{ fill: "var(--blue)", r: 2 }}
              activeDot={{ r: 4 }}
              animationDuration={0}
            />
          </LineChart>
        </ResponsiveContainer>
        {title && (
          <Heading
            size="sm"
            fontWeight={400}
            fontSize="14px"
            color="#535353"
            mb="20px"
            textAlign="center"
          >
            {title}
          </Heading>
        )}
      </Box>
    </>
  );
};

export default AgentServiceTimePerServiceLineGraph;
