import React, { useEffect, useState } from "react";
import { Box, ChakraProvider, extendTheme } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import _ from "lodash";

// Organization
import MonthlyOrganizationTickets from "./pages/organization/OrganizationTickets";
import MonthlyOrganizationTicketsPerService from "./pages/organization/OrganizationTicketsPerService";
import MonthlyOrganizationServiceTimePerService from "./pages/organization/OrganizationServiceTimePerService";
import MonthlyOrganizationArrivalRatesPerHour from "./pages/organization/OrganizationArrivalRatesPerHour";
import MonthlyOrganizationArrivalRatesPerDay from "./pages/organization/OrganizationArrivalRatesPerDay";
import MonthlyOrganizationArrivalRatesPerService from "./pages/organization/OrganizationArrivalRatesPerService";
import MonthlyOrganizationTicketsPerBranch from "./pages/organization/OrganizationTicketsPerBranch";

// Arrival rates
import MonthlyBranchArrivalRatesPerHour from "./pages/branches/BranchArrivalRatesPerHour";
import MonthlyBranchArrivalRatesPerDay from "./pages/branches/BranchArrivalRatesPerDay";
import MonthlyBranchArrivalRatesPerService from "./pages/branches/BranchArrivalRatesPerService";

// Service times
import MonthlyBranchServiceTimePerService from "./pages/branches/BranchServiceTimePerService";
import MonthlyBranchAgentServiceTimePerService from "./pages/branches/BranchAgentServiceTimePerService";

// Ticket Management
import MonthlyBranchTicketsPerService from "./pages/branches/BranchTicketsPerService";
import MonthlyBranchTicketsPerAgent from "./pages/branches/BranchTicketsPerAgent";
import MonthlyBranchAgentTicketsPerService from "./pages/branches/BranchAgentTicketsPerService";

//Customers
import MonthlyBranchCustomersInBranchPerHour from "./pages/branches/BranchCustomersInBranchPerHour";

//weekly
// Organization
import WeeklyOrganizationTickets from "./pages/organization/OrganizationTickets";
import WeeklyOrganizationTicketsPerService from "./pages/organization/OrganizationTicketsPerService";
import WeeklyOrganizationServiceTimePerService from "./pages/organization/OrganizationServiceTimePerService";
import WeeklyOrganizationArrivalRatesPerHour from "./pages/organization/OrganizationArrivalRatesPerHour";
import WeeklyOrganizationArrivalRatesPerDay from "./pages/organization/OrganizationArrivalRatesPerDay";
import WeeklyOrganizationArrivalRatesPerService from "./pages/organization/OrganizationArrivalRatesPerService";
import WeeklyOrganizationTicketsPerBranch from "./pages/organization/OrganizationTicketsPerBranch";

// Arrival rates
import WeeklyBranchArrivalRatesPerHour from "./pages/branches/BranchArrivalRatesPerHour";
import WeeklyBranchArrivalRatesPerDay from "./pages/branches/BranchArrivalRatesPerDay";
import WeeklyBranchArrivalRatesPerService from "./pages/branches/BranchArrivalRatesPerService";

// Service times
import WeeklyBranchServiceTimePerService from "./pages/branches/BranchServiceTimePerService";
import WeeklyBranchAgentServiceTimePerService from "./pages/branches/BranchAgentServiceTimePerService";

// Ticket Management
import WeeklyBranchTicketsPerService from "./pages/branches/BranchTicketsPerService";
import WeeklyBranchTicketsPerAgent from "./pages/branches/BranchTicketsPerAgent";
import WeeklyBranchAgentTicketsPerService from "./pages/branches/BranchAgentTicketsPerService";

//Customers
import WeeklyBranchCustomersInBranchPerHour from "./pages/branches/BranchCustomersInBranchPerHour";

//daily reports
//organization
import DailyOrganizationArrivalRatesPerHour from "./pages/organization/daily/DailyOrganizationArrivalRatesPerHour";
import DailyOrganizationArrivalRatesPerService from "./pages/organization/daily/DailyOrganizationArrivalRatesPerService";
import DailyOrganizationServiceTimePerService from "./pages/organization/daily/DailyOrganizationServiceTimePerService";
import DailyOrganizationTickets from "./pages/organization/daily/DailyOrganizationTickets";
import DailyOrganizationTicketsPerBranch from "./pages/organization/daily/DailyOrganizationTicketsPerBranch";
import DailyOrganizationTicketsPerService from "./pages/organization/daily/DailyOrganizationTicketsPerService";

//branch
import DailyBranchAgentServiceTimePerService from "./pages/branches/daily/DailyBranchAgentServiceTimePerService";
import DailyBranchArrivalRatesPerService from "./pages/branches/daily/DailyBranchArrivalRatesPerService";
import DailyBranchServiceTimePerService from "./pages/branches/daily/DailyBranchServiceTimePerService";
import DailyBranchTicketsPerService from "./pages/branches/daily/DailyBranchTicketsPerService";
import DailyBranchAgentTicketsPerService from "./pages/branches/daily/DailyBranchAgentTicketsPerService";
import DailyBranchArrivalRatesPerHour from "./pages/branches/daily/DailyBranchArrivalRatesPerHour";
import DailyBranchTicketsPerAgent from "./pages/branches/daily/DailyBranchTicketsPerAgent";

//customers
import DailyBranchCustomersInBranchPerHour from "./pages/branches/daily/BranchCustomersInBranchPerHour";

// Cover
import Cover from "./pages/Cover";

//TOC
import TOC from "./pages/TOC";

// import css
import "./assets/css/All.css";
import "./assets/css/Print.css";
import Loader from "./components/loader";
import { useDispatch } from "react-redux";
import { updateAppStatus } from "./store/actions/authActions";
import {
  clearReports,
  getConfig,
  getLogoData,
  getMailingList,
  getReportTypes,
  getReportsData,
  updateReportRange,
} from "./store/actions/defaultAction";

function App() {
  const [reportDataId, setReportDataId] = useState("");
  const [page, setPage] = useState("");
  const dispatch = useDispatch();

  const {
    reportTypes,
    reportsData,
    mailingList,
    config,
    logoData,
    reportRange,
  } = useSelector((state) => state.defaultReducer);
  const { appStatus } = useSelector((state) => state.authReducer);

  // on load
  useEffect(() => {
    if (!reportTypes.length) {
      dispatch(getReportTypes());
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.forEach((value, key) => {
      if (key === "id") {
        setReportDataId(value);
      }
      if (key === "reportRange") {
        dispatch(updateReportRange(value));
      }
      if (key === "mailingListId") {
        dispatch(clearReports());
        dispatch(getMailingList(value));
      }
      if (key === "page") {
        setPage(value);
      }
    });
  }, []);

  useEffect(() => {
    if (mailingList) {
      dispatch(getConfig(mailingList.organizationId));
    }
  }, [mailingList]);

  useEffect(() => {
    const logoStorageId = config?.styling?.general?.logoStorageId;
    if (logoStorageId) {
      dispatch(getLogoData(logoStorageId));
    }
  }, [config]);

  useEffect(() => {
    if (mailingList && config && logoData) dispatch(updateAppStatus(1));
  }, [mailingList, config, logoData]);

  // after getting id from url, get qm_reports_data by ID from db
  useEffect(() => {
    if (reportTypes && reportDataId) dispatch(getReportsData(reportDataId));
  }, [reportDataId]);

  // update appStatus when everything is fetched
  useEffect(() => {
    if (!appStatus && reportTypes && reportsData && reportRange.length > 0) {
      dispatch(updateAppStatus(1));
    }
  }, [appStatus, reportTypes, reportsData, reportRange]);

  const theme = extendTheme({
    fonts: {
      body: "Lato, sans-serif",
      heading: "Lato, sans-serif",
    },
  });

  const components = reportTypes.map(({ id, name }) => {
    let componentName = name
      .replace(/_([a-z])/g, (match, letter) => letter.toUpperCase())
      .replace(/^./, (str) => str.toUpperCase());

    return { componentName, id };
  });

  const componentsMap = {
    MonthlyOrganizationTickets,
    MonthlyOrganizationTicketsPerService,
    MonthlyOrganizationServiceTimePerService,
    MonthlyOrganizationArrivalRatesPerHour,
    MonthlyOrganizationArrivalRatesPerDay,
    MonthlyOrganizationArrivalRatesPerService,
    MonthlyOrganizationTicketsPerBranch,
    MonthlyBranchArrivalRatesPerHour,
    MonthlyBranchArrivalRatesPerDay,
    MonthlyBranchArrivalRatesPerService,
    MonthlyBranchServiceTimePerService,
    MonthlyBranchAgentServiceTimePerService,
    MonthlyBranchTicketsPerService,
    MonthlyBranchTicketsPerAgent,
    MonthlyBranchAgentTicketsPerService,
    MonthlyBranchCustomersInBranchPerHour,
    WeeklyOrganizationTickets,
    WeeklyOrganizationTicketsPerService,
    WeeklyOrganizationServiceTimePerService,
    WeeklyOrganizationArrivalRatesPerHour,
    WeeklyOrganizationArrivalRatesPerDay,
    WeeklyOrganizationArrivalRatesPerService,
    WeeklyOrganizationTicketsPerBranch,
    WeeklyBranchArrivalRatesPerHour,
    WeeklyBranchArrivalRatesPerDay,
    WeeklyBranchArrivalRatesPerService,
    WeeklyBranchServiceTimePerService,
    WeeklyBranchAgentServiceTimePerService,
    WeeklyBranchTicketsPerService,
    WeeklyBranchTicketsPerAgent,
    WeeklyBranchAgentTicketsPerService,
    WeeklyBranchCustomersInBranchPerHour,
    DailyOrganizationArrivalRatesPerHour,
    DailyOrganizationArrivalRatesPerService,
    DailyOrganizationServiceTimePerService,
    DailyOrganizationTickets,
    DailyOrganizationTicketsPerBranch,
    DailyOrganizationTicketsPerService,
    DailyBranchAgentServiceTimePerService,
    DailyBranchArrivalRatesPerService,
    DailyBranchServiceTimePerService,
    DailyBranchTicketsPerService,
    DailyBranchTicketsPerAgent,
    DailyBranchAgentTicketsPerService,
    DailyBranchArrivalRatesPerHour,
    DailyBranchCustomersInBranchPerHour,
  };

  const reportTypeId = reportsData[0]?.reportTypeId;
  const componentName = components.find(
    (a) => a.id === reportTypeId
  )?.componentName;
  const ReportComponent = componentsMap[componentName];

  return (
    <ChakraProvider theme={theme}>
      <Box m={10}>
        {appStatus === 1 && ReportComponent && (
          <Box id="reportContent" className="report-content">
            <ReportComponent showFilterForm={false} />
          </Box>
        )}
        {appStatus && !_.isEmpty(mailingList) && page === "cover" && (
          <Box id="coverContent">
            <Cover />
          </Box>
        )}

        {appStatus && !_.isEmpty(mailingList) && page === "toc" && (
          <Box id="tocContent">
            <TOC />
          </Box>
        )}
      </Box>
    </ChakraProvider>
  );
}

export default App;
