import {
  Stat,
  StatLabel,
  StatNumber,
  Icon,
} from '@chakra-ui/react';
import { useId } from 'react';
import PropTypes from 'prop-types';


function StatsCard({
  title, stat, statNumberId, icon, iconColor,
}) {
  // prop validation
  StatsCard.propTypes = {
    title: PropTypes.string.isRequired,
    stat: PropTypes.number.isRequired,
    statNumberId: PropTypes.string,
    icon: PropTypes.elementType,
    iconColor: PropTypes.string,
    filterStatus: PropTypes.number,
    to: PropTypes.string,

  };
  // default prop values
  StatsCard.defaultProps = {
    statNumberId: '',
    icon: null,
    iconColor: 'var(--gray-8)',
    filterStatus: undefined,
    to: '',
  };

  const generatedId = useId();
  const id = statNumberId || generatedId;

  return (
    <Stat
    >
      <StatLabel fontWeight="400" color="var(--gray-8)">
        {title}
      </StatLabel>
      <StatNumber fontSize="24px" fontWeight="600" color="var(--black-dry)" id={id}>
        {stat}
      </StatNumber>
      { icon ? <Icon as={icon} color={iconColor} boxSize="28px" position="absolute" bottom="-5px" right="-10px" /> : null}
    </Stat>
  );
}

export default StatsCard;
