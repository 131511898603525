import { Box, Text } from "@chakra-ui/react";
import ArrivalRatesPerHourTable from "../../tables/daily/ArrivalRatesPerHourTable";

const BranchArrivalRatesPerHour = ({ reportIndex, data }) => {
  return (
    <Box>
      {data.map((data, index) => {
        const { branchName, numServices } = data;
        const title = `${reportIndex || ""}.${index + 1}. ${branchName}`;
        console.log(title);
        return (
          <Box key={index}>
            <h2>{title}</h2>
            <Text>{numServices} services</Text>
            <ArrivalRatesPerHourTable
              data={data}
              index={index}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default BranchArrivalRatesPerHour;
