import _ from "lodash";
import { Box, SimpleGrid } from "@chakra-ui/react";
import {
  IoTimeOutline,
  IoLayersOutline,
  IoPeopleOutline,
} from "react-icons/io5";
import LineGraph from "../../daily/LineGraph";
import StatsCard from "../../StatsCard";
import { formatNumber } from "../../../store/helpers";

const CustomersInBranchPerHour = ({ data, reportIndex }) => {
  return (
    <Box>
      <Box>
        {data.map((data, index) => {
          const {
            branchName,
            chart,
            average,
            peakHour,
            total,
            max,
            min,
            abondonmentRate,
          } = data;
          const title = `${index + 1}. ${branchName}`;

          const stats = [
            {
              title: "Avg. Total Customers Per Day",
              stat: formatNumber(total),
              icon: IoLayersOutline,
              iconColor: "var(--gray-8)",
            },
            {
              title: "Avg. Min. Customers Per Hour",
              stat: min,
              icon: IoPeopleOutline,
              iconColor: "var(--red)",
            },
            {
              title: "Avg. Max. Customers Per Hour",
              stat: max,
              icon: IoPeopleOutline,
              iconColor: "var(--green)",
            },

            {
              title: "Avg. Customers in Branch Per Hour",
              stat: average,
              icon: IoPeopleOutline,
              iconColor: "var(--orange)",
            },
            {
              title: "Abondonment Rate (%)",
              stat: abondonmentRate,
            },
            {
              title: "Peak Hour",
              stat: peakHour,
              icon: IoTimeOutline,
              iconColor: "var(--green)",
            },
          ];

          return (
            <Box key={index}>
              <h2>{title}</h2>
              <Box mt={4}>
                <SimpleGrid
                  columns={{ base: 1, md: 3 }}
                  spacing={0}
                  mb={10}
                  mt="20px"
                >
                  {stats.map((a, i) => (
                    <Box
                      px={"20px"}
                      py={"15px"}
                      key={i}
                      mb={"5px"}
                      border={"1px solid var(--borderGrey)"}
                      borderRight={{
                        base: "1px solid #E5E4E5",
                        md: [2, 5].includes(i) ? "1px solid #E5E4E5" : "none",
                      }}
                      borderRadius={{
                        base: "10px",
                        md: [0, 3].includes(i)
                          ? "10px 0 0 10px"
                          : [2, 5].includes(i)
                          ? "0 10px 10px 0"
                          : "0",
                      }}
                    >
                      <StatsCard
                        title={a.title}
                        stat={a.stat}
                        icon={a.icon}
                        iconColor={a.iconColor}
                      />
                    </Box>
                  ))}
                </SimpleGrid>

                <Box mt="20px" mb="20px" pt={"10px"}>
                  <LineGraph
                    chart={chart}
                    title={`${branchName} Number of Customers in Branch, Hour by Hour Comparison`}
                    lineName="Customers"
                  />
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default CustomersInBranchPerHour;
