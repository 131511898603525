import { Box, Text } from "@chakra-ui/react";

import { useSelector } from "react-redux";
import { REPORT_TYPE_IDS } from "../../../store/constants";
import Loader from "../../../components/loader";
import {
  getReportDescription,
} from "../../../store/helpers";
import BranchCustomersInBranchPerHourReport from "../../../components/branches/reports/BranchCustomersInBranchPerHour";
import _ from "lodash";

const BranchCustomersInBranchPerHour = ({
  reportIndex = "**REPORT_INDEX**",
}) => {
  const { loading, reportRange, reportsData, branchId } = useSelector(
    (state) => state.defaultReducer
  );

  const reportTypeId = REPORT_TYPE_IDS.daily_branch_customers_in_branch_per_hour;

  const reportsDataClone = _.cloneDeep(reportsData);
  let reports = reportsDataClone.filter((a) => a.reportTypeId === reportTypeId);
  if (branchId.length > 0) {
    reports = reports.filter((a) => a.branchId === branchId);
  }
  const data = reports.map((a) => a.data) || [];

  const title = `${reportIndex}. Customers In Branch Per Hour`;

  return (
    <Box>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {" "}
          {!_.isEmpty(data) ? (
            <>
              <h1>{title}</h1>
              <p>
                {
                  getReportDescription(reportRange)
                    .BranchesCustomersInBranchPerHour
                }
              </p>
              <BranchCustomersInBranchPerHourReport
                data={data}
                reportIndex={reportIndex}
              />
            </>
          ) : (
            <Box>
              <Text>No data available in this report</Text>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default BranchCustomersInBranchPerHour;
