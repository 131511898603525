import {
  List,
  ListItem,
  Box,
  Heading,
  Center,
  HStack,
  Text,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
const TOC = () => {
  const { mailingList, logoData, config } = useSelector(
    (state) => state.defaultReducer
  );
  const { toc } = mailingList;
  // Find the length of the longest title

  const headings = toc.filter((a) => a?.index !== null);
  const longestTitleLength = Math.max(
    ...headings.map((item) => item.title.length)
  );

  // Calculate padding based on the longest title
  const calculatePadding = (title) => {
    const extraPadding = 70;
    let paddingLength = longestTitleLength - title.length + extraPadding;
    return paddingLength;
  };
  return (
    <Box>
      <Center>
        <Heading>Table of Contents</Heading>
      </Center>
      <List spacing={3}>
        {toc.map(({ title, pageStart }, index) => (
          <ListItem key={index}>
            {!pageStart && title}
            {pageStart && (
              <HStack maxWidth="90%">
                <Text fontSize="18px" whiteSpace={"nowrap"} fontWeight="bold">
                  {title}
                </Text>
                <Box
                  as="span"
                  noW
                  ml={2}
                  fontWeight={"normal"}
                  whiteSpace={"nowrap"}
                  fontSize="sm"
                  color="gray.400"
                  overflowX="hidden"
                >
                  {"-".repeat(calculatePadding(title))}
                </Box>
                <Box
                  position="absolute"
                  right={0}
                  mr="60px"
                  bgColor="#fff"
                  zIndex={1000}
                >
                  <Text ml={"10px"}>Page {pageStart}</Text>
                </Box>
              </HStack>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default TOC;
