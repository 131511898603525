import {
  Box,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { ARRIVAL_RATES_FREQUENCIES, LEVELS } from "../../../store/constants";

import AgentTicketStatusGraphs from "../charts/AgentTicketStatusGraphs";
import { formatNumber } from "../../../store/helpers";

const AgentTables = ({
  data,
  totalBranchTickets,
  branchName,
  reportIndex,
  branchIndex,
}) => {
  return (
    <>
      {data.map((agentData, index) => {
        const {
          min,
          max,
          total,
          average,
          numCompleted,
          numNoShow,
          numUnattended,
          pctCompleted,
          pctNoShow,
          pctUnattended,
          agentName,
          charts
        } = agentData;

        let totalPct = (total / totalBranchTickets) * 100 || 0;
        const title = `${reportIndex || ""}.${branchIndex + 1}.${
          index + 1
        }. ${agentName}`;
        console.log(title);
        return (
          <>
            <Box className="reportContainer" mt="20px">
              <h3>{title}</h3>
              <Tag>
                <p className="branchTag">{branchName} Branch</p>
              </Tag>
              {total ? (
                <>
                  {" "}
                  <Table className="stripedTable" size="sm">
                    <Thead>
                      <Tr>
                        <Th>#</Th>
                        <Th borderRight="1px solid #dadce0">Total Tickets</Th>
                        <Th borderRight="1px solid #dadce0">Average</Th>
                        <Th>Min</Th>
                        <Th>Max</Th>
                        <Th>Completed</Th>
                        <Th>No Show</Th>
                        <Th>Unattended</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>{index + 1}</Td>
                        <Td borderRight="1px solid #dadce0">
                          {" "}
                          <Text color={"var(--green)"}>
                            {formatNumber(total)}
                            <Box
                              color="gray.500"
                              fontWeight="semibold"
                              fontSize="xs"
                              textTransform="uppercase"
                            >
                              ({totalPct.toFixed(2)}%)
                            </Box>
                          </Text>
                        </Td>
                        <Td borderRight="1px solid #dadce0">
                          {formatNumber(average)}
                        </Td>
                        <Td>{formatNumber(min)}</Td>
                        <Td>{formatNumber(max)}</Td>
                        <Td>
                          {" "}
                          <Text color={"var(--green)"}>
                            {formatNumber(numCompleted)}
                            <Box
                              color="gray.500"
                              fontWeight="semibold"
                              fontSize="xs"
                              textTransform="uppercase"
                            >
                              ({pctCompleted.toFixed(2)}%)
                            </Box>
                          </Text>
                        </Td>

                        <Td>
                          {" "}
                          <Text color={"var(--red)"}>
                            {formatNumber(numNoShow)}
                            <Box
                              color="gray.500"
                              fontWeight="semibold"
                              fontSize="xs"
                              textTransform="uppercase"
                            >
                              ({pctNoShow.toFixed(2)}%)
                            </Box>
                          </Text>
                        </Td>

                        <Td>
                          {" "}
                          <Text color={"var(--red)"}>
                            {formatNumber(numUnattended)}
                            <Box
                              color="gray.500"
                              fontWeight="semibold"
                              fontSize="xs"
                              textTransform="uppercase"
                            >
                              ({pctUnattended.toFixed(2)}%)
                            </Box>
                          </Text>
                        </Td>
                      </Tr>

                      <Tr>
                        <Td colSpan={8}>
                          <Box mt="20px" mb="20px">
                            <AgentTicketStatusGraphs
                            charts={charts}
                              data={data}
                              frequency={ARRIVAL_RATES_FREQUENCIES.DAY}
                              level={LEVELS.BRANCH}
                              title={`${agentName} Tickets, Day by Day Comparison`}
                            />
                          </Box>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </>
              ) : (
                <>
                  {" "}
                  <Text fontSize="sm">No data available for this report</Text>
                </>
              )}
            </Box>
          </>
        );
      })}
    </>
  );
};

const TicketsPerAgentTable = ({ data, branchId, reportIndex, branchIndex }) => {

    const {agentsData, branchName} = data;
  const totalBranchTickets = agentsData
    .map((a) => a.total)
    .reduce((acc, val) => {
      acc += val;
      return acc;
    }, 0);

  return (
    <Box>
      <Text>{agentsData.length} agents.</Text>
      {!agentsData.length && (
        <Text fontSize="sm">No data available for this report</Text>
      )}

      {agentsData.length && (
        <>
          <AgentTables
            totalBranchTickets={totalBranchTickets}
            reportIndex={reportIndex}
            branchIndex={branchIndex}
            data={agentsData}
            branchName={branchName}
          />
        </>
      )}
    </Box>
  );
};

export default TicketsPerAgentTable;
