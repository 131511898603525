import React from 'react';
import _ from 'lodash';

import {
    Box,
} from '@chakra-ui/react';

import AllTicketsTable from "../tables/AllTicketsTable";

const Tickets = ({
    reportIndex,
    data
}) => {

    return (
        <Box>
            <AllTicketsTable 
            table={[data]} 
            chart={data.chart} 
            reportIndex={reportIndex} 
        />
        </Box>
    );
};


  
export default Tickets;
  