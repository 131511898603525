import { connect } from "react-redux";
import React from 'react';
import {
    Box,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Text,
} from '@chakra-ui/react';
import _ from 'lodash';
import TicketsByServiceLineGraph from "../charts/TicketsPerServiceLineGraph";

const { formatNumber } = require('../../../store/helpers');

const AllTicketsTable = ({
  table,
  chart, reportIndex
}) => { 

  return (
    <Box mt={10}>
      
      <Box>
        {
          table.map( (a, index) => 
          <Box className="reportContainer">
            <Box mb="10px">
              { console.log(`${reportIndex}. ${index+1}. ${a.name}`) }
              <h3>{reportIndex}. {index+1}. {a.name}</h3>
            </Box>
            <Table key={index} className="stripedTable" size="sm">
              <Thead>
                <Tr>
                  <Th borderRight="1px solid #dadce0">Total Tickets</Th>
                  <Th>Avg /day.</Th>
                  <Th>Min.</Th>
                  <Th borderRight="1px solid #dadce0">Max</Th>
                  <Th>Completed</Th>
                  <Th>No Show</Th>
                  <Th>Unattended</Th>
                </Tr>
              </Thead>
              <Tbody>

                <Tr >
                  <Td borderRight="1px solid #dadce0">
                    <Text color={'var(--blue)'}>{formatNumber(a.total)}</Text>
                  </Td>
                  <Td>
                    <Text>{formatNumber(a.averageTicketsPerDay)}</Text>
                  </Td>
                  <Td>
                    <Text>{formatNumber(a.minTicketsPerDay)}</Text>
                  </Td>
                  <Td borderRight="1px solid #dadce0">
                    <Text>{formatNumber(a.maxTicketsPerDay)}</Text>
                  </Td>
                  <Td>
                    <Text color={'var(--green)'}>
                      {formatNumber(a.completed)}
                      <Box color='gray.500' fontWeight='semibold' fontSize='xs' textTransform='uppercase'>({a.pctCompleted.toFixed(2)}%)</Box>
                    </Text>
                  </Td>
                  <Td>
                    <Text cursor="pointer" color={'var(--red)'}>
                      {formatNumber(a.noShow)} 
                      <Box color='gray.500' fontWeight='semibold' fontSize='xs' textTransform='uppercase'>({a.pctNoShow.toFixed(2)}%)</Box>
                    </Text>
                  </Td>
                  <Td>
                    <Text color={'var(--red)'}>
                      {formatNumber(a.unattended)} 
                      <Box color='gray.500' fontWeight='semibold' fontSize='xs' textTransform='uppercase'>({a.pctUnattended.toFixed(2)}%)</Box>
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td colSpan={9} p={0}>
                    <Box mt="20px" mb="20px">
                      <TicketsByServiceLineGraph title={`Entire Organization: Day by day comparison`} chart={chart} chartHeight="300px" />
                    </Box>
                  </Td>
                </Tr>
              </Tbody>
              
            </Table>
          </Box>
          )
        }
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
    const { defaultReducer } = state
    return {
      tickets: defaultReducer.tickets,
    };
};
  
export default connect(mapStateToProps, {})(
    AllTicketsTable
);
  